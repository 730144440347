<template>
	<v-container id="crud" fluid tag="section">
		<v-card>
			<v-card-title>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					:label="'Busqueda de ' + title"
					single-line
					hide-details
				></v-text-field>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="desserts"
				:search="search"
				sort-by="secret"
				class="elevation-1"
			>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
						<v-divider class="mx-4" inset vertical></v-divider>
						<v-spacer></v-spacer>

						<v-dialog v-model="dialog" max-width="50%" persistent>
							<template v-if="visible" v-slot:activator="{ on, attrs }">
								<v-btn
									:color="$cv('principal')"
									dark
									class="mx-2"
									fab
									v-bind="attrs"
									v-on="on"
									><v-icon blue>mdi-plus</v-icon></v-btn
								>
							</template>
							<v-card>
								<v-form ref="form" v-model="valid" lazy-validation>
									<v-toolbar dark :color="$cv('principal')">
										<v-btn icon dark @click="dialog = false">
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
										<v-spacer></v-spacer>
										<v-toolbar-items>
											<v-btn dark text @click="save()" :disabled="!valid"
												>Guardar</v-btn
											>
										</v-toolbar-items>
									</v-toolbar>

									<v-card-text class="card-scrolleable">
										<v-container>
											<v-row>
												<v-col md="12" sm="12">
													<v-text-field
														v-model="editedItem.titulo"
														label="Titulo"
														filled
														dense
														class="input-h1"
													></v-text-field>
												</v-col>
											</v-row>
											<v-row v-if="modulo == 'C'">
												<v-col cols="12" md="12" sm="12">
													<v-autocomplete
														:items="choferes"
														item-value="id"
														item-text="chofer"
														label="Chofer"
														v-model="editedItem.chofer_id"
													></v-autocomplete>
												</v-col>
											</v-row>
											<v-row v-else-if="modulo == 'S'">
												<v-col cols="12" md="12" sm="12">
													<v-autocomplete
														:items="seguros"
														item-value="id"
														item-text="nombre"
														label="Seguro"
														v-model="editedItem.seguro_id"
													></v-autocomplete>
												</v-col>
											</v-row>
											<v-row v-else>
												<v-col cols="12" md="12" sm="12">
													<v-autocomplete
														:items="vehiculos"
														item-value="id"
														item-text="vehiculo"
														label="Vehiculo"
														v-model="editedItem.vehiculo_id"
													></v-autocomplete>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="12" md="4" sm="12">
													<v-autocomplete
														:items="estados"
														item-value="id"
														item-text="detalle"
														label="Estado"
														v-model="editedItem.estado"
													></v-autocomplete>
												</v-col>
												<v-col cols="12" md="4" sm="12">
													<v-autocomplete
														:items="itemsSN"
														item-value="id"
														item-text="detalle"
														label="Notificar"
														v-model="editedItem.notificar"
													></v-autocomplete>
												</v-col>
												<v-col cols="12" md="4" sm="12">
													<v-autocomplete
														:items="tipo_alertas"
														item-value="id"
														item-text="nombre"
														label="Tipo de Alerta"
														@change="isFecha(editedItem.tipo_alerta_id)"
														v-model="editedItem.tipo_alerta_id"
													></v-autocomplete>
												</v-col>
											</v-row>
											<v-row v-if="valorFecha">
												<v-col md="6" sm="6">
													<v-dialog
														ref="dialog"
														v-model="modal"
														:return-value.sync="editedItem.valor"
														persistent
														width="290px"
													>
														<template v-slot:activator="{ on, attrs }">
															<v-text-field
																v-model="computedValor"
																label="Vencimiento"
																prepend-icon="event"
																readonly
																v-on="on"
																v-bind="attrs"
															></v-text-field>
														</template>
														<v-date-picker
															v-model="editedItem.valor"
															scrollable
														>
															<v-spacer></v-spacer>
															<v-btn
																:color="$cv('principal')"
																@click="modal = false"
																>Cancel</v-btn
															>
															<v-btn
																:color="$cv('principal')"
																@click="$refs.dialog.save(editedItem.valor)"
																>OK</v-btn
															>
														</v-date-picker>
													</v-dialog>
												</v-col>
											</v-row>
											<v-row v-else>
												<v-col cols="12" md="12" sm="12">
													<v-text-field
														v-model="editedItem.valor"
														label="Valor"
														filled
														dense
														class="input-h1"
													></v-text-field>
												</v-col>
											</v-row>
										</v-container>
									</v-card-text>
								</v-form>
							</v-card>
						</v-dialog>
					</v-toolbar>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-icon
						v-if="visible"
						small
						class="mr-2"
						:color="$cv('btnEditar')"
						title="Editar"
						@click="editItem(item.id)"
					>
						mdi-pencil
					</v-icon>
					<v-icon
						v-if="visible"
						small
						:color="$cv('btnEliminar')"
						title="Eliminar"
						@click="deleteItem(item.id, item.titulo)"
					>
						mdi-delete
					</v-icon>
				</template>
			</v-data-table>
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			<div v-html="text"></div>

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
		<vue-confirm-dialog></vue-confirm-dialog>
	</v-container>
</template>

<script>
	function title() {
		return "Alertas";
	}

	export default {
		props: {
			modulo: { String, default: null },
		},
		data: (vm) => ({
			valorFecha: true,
			tipo_alertas: [],
			valid: true,
			nowDate: new Date().toISOString().slice(0, 10),
			_method: "PUT",
			autoGrow: true,
			rows: 1,
			title: title(),
			route: "alertas",
			menu: false,
			modal: false,
			dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
			search: "",
			rulesRequired: [(v) => !!v || "Requerido"],
			headers: [
				/*{
																			                text: "Id",
																			                sortable: false,
																			                value: "id",
																			                align: "d-none",
																			            },*/
				{ text: "Titulo", filterable: true, value: "titulo" },
				/*{ text: "Generacion", filterable: true, value: "generacion" },*/
				{ text: "Estado", filterable: true, value: "estado" },
				{ text: "Notificar", filterable: true, value: "notificar" },
				{ text: "Tipo Alerta", filterable: true, value: "tipo_alerta" },
				{ text: "Creado", value: "created_at" },
				{ text: "Actualizado", value: "updated_at" },
				{ text: "Acciones", value: "actions", sortable: false },
			],

			desserts: [],
			editedIndex: -1,
			editedItem: {
				id: "",
				titulo: "",
				generacion: "",
				estado: "",
				notificar: "",
				tipo_alerta_id: "",
				vehiculo_id: "",
				seguro_id: "",
				chofer_id: "",
				valor: "",
			},
			itemsSN: [
				{ id: "S", detalle: "SI" },
				{ id: "N", detalle: "NO" },
			],
			estados: [
				{ id: 1, detalle: "Abierta" },
				{ id: 2, detalle: "Cerrada" },
			],
			choferes: [],
			vehiculos: [],
			seguros: [],
			defaultItem: {},
		}),

		computed: {
			formTitle() {
				return this.editedIndex === -1
					? "Registrar " + this.title
					: "Editar " + this.title;
			},
			computedValor() {
				return this.formatDate(this.editedItem.valor);
			},
		},

		watch: {
			dialog(val) {
				val || this.close();
			},
		},

		created() {
			switch (this.modulo) {
				case "C":
					this.title = this.title + " de Choferes";
					this.headers.splice(0, 0, {
						text: "Chofer",
						value: "chofer",
					});
					this.choferes = this.getChoferes();
					//this.initialize();
					break;
				case "S":
					this.title = this.title + " de Seguros";
					this.headers.splice(0, 0, {
						text: "Seguro",
						value: "seguros",
					});
					this.seguros = this.getSeguros();
					//this.initialize();
					break;
				case "V":
					this.title = this.title + " de Vehiculos";
					this.headers.splice(0, 0, {
						text: "Vehiculo",
						value: "vehiculo",
					});
					this.vehiculos = this.getVehiculos();
					break;
			}

			this.getTipos();
			this.initialize();
		},

		methods: {
			isFecha(value) {
				if (value.fecha == "S") {
					this.valorFecha = true;
				} else {
					this.valorFecha = false;
				}
				//return value.fecha == "S" ? true : false;
			},
			getChoferes() {
				this.$axiosApi
					.get("getListChoferes")
					.then((response) => {
						this.choferes = response.data;
					})
					.catch((response) => {
						this.snackbar = true;
						this.text = "Error al obtener registos. Error: " + response.message;
						this.color = "error";
					});
			},
			getSeguros() {
				this.$axiosApi
					.get("seguros")
					.then((response) => {
						this.seguros = response.data;
					})
					.catch((response) => {
						this.snackbar = true;
						this.text = "Error al obtener registos. Error: " + response.message;
						this.color = "error";
					});
			},
			getVehiculos() {
				this.$axiosApi
					.get("getListVehiculos")
					.then((response) => {
						this.vehiculos = response.data;
					})
					.catch((response) => {
						this.snackbar = true;
						this.text = "Error al obtener registos. Error: " + response.message;
						this.color = "error";
					});
			},
			getTipos() {
				this.$axiosApi
					.sendRequest(
						this.$axiosApi.routes["getListTipoAlertas"] + "/" + this.modulo,
						"get"
					)
					//.get("tipo_alertas")
					.then((response) => {
						this.tipo_alertas = response.data;
					})
					.catch((response) => {
						this.snackbar = true;
						this.text = "Error al obtener registos. Error: " + response.message;
						this.color = "error";
					});
			},
			formatDate(date) {
				if (!date) return null;

				const [year, month, day] = date.split("-");
				return `${day}/${month}/${year}`;
			},

			capitalize(s) {
				if (typeof s !== "string") return "";
				return s.charAt(0).toUpperCase() + s.slice(1);
			},

			initialize() {
				//let url = this.modulo
				//	? "getAlertasByModulo/" + this.modulo
				//	: this.baseUrl;
				//console.log("modulo " + this.modulo);
				if (this.modulo) {
					this.$axiosApi
						.getByCriteria("getAlertasByModulo", this.modulo)
						.then((r) => {
							console.log(r.data);
							this.desserts = r.data;
						})
						.catch(function (error) {
							this.snackbar = true;
							this.text = "Error al obtener datos. Error: " + error;
							this.color = "error";
						});
				} else {
					this.$axiosApi
						.get("alertas")
						.then((r) => {
							console.log(r.data);
							this.desserts = r.data;
						})
						.catch(function (error) {
							this.snackbar = true;
							this.text = "Error al obtener datos. Error: " + error;
							this.color = "error";
						});
				}
			},

			editItem(id) {
				this.$axiosApi
					.getById(this.route, id)
					.then((r) => {
						this.respuesta(r, 3);
					})
					.catch(function (error) {
						this.respuesta(error, 3, true);
					});
			},

			deleteItem(id, nombre) {
				this.$confirm({
					message: "¿Desea ELIMINAR " + this.title + " " + nombre + "?",
					button: {
						no: "Cancelar",
						yes: "Confirmar",
					},

					callback: (confirm) => {
						if (confirm) {
							this.$axiosApi.delete(this.route, id).then(
								(response) => {
									this.snackbar = true;
									this.text = "Registro Eliminado";
									this.initialize();
								},
								(error) => {
									this.snackbar = true;
									this.text =
										"Error al eliminar los datos.Contacte al administrador del sistema";
									this.color = "error";
								}
							);
						}
					},
				});
			},

			close() {
				this.dialog = false;
				this.valid = true;
				this.resetValidation();
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				});
			},
			resetValidation() {
				this.$refs.form.resetValidation();
			},

			save() {
				if (this.$refs.form.validate()) {
					if (this.editedIndex > -1) {
						this.$axiosApi
							.put(this.route, this.editedIndex, this.editedItem)
							.then((r) => {
								this.respuesta(r, 2);
							})
							.catch((e) => {
								this.respuesta(e, 2, true);
							});
					} else {
						this.$axiosApi
							.post(this.route, this.editedItem)
							.then((r) => {
								this.respuesta(r, 1);
							})
							.catch((e) => {
								this.respuesta(e, 1, true);
							});
					}
				}
			},

			respuesta(r, tipo = 1, excepcion = false) {
				let text1 = "Creado";
				let text2 = "crear";
				switch (tipo) {
					case 0:
						text1 = "Eliminado";
						text2 = "eliminar";
						break;
					case 2:
						text1 = "Actualizado";
						text2 = "actualizar";
						break;
					case 3:
						text1 = "Obtener";
						text2 = "actualizar";
						break;
				}
				if (excepcion == false) {
					if (tipo == 3) {
						if (r.data && r.data.status == 200) {
							this.editedIndex = r.data.data.id;
							delete r.data.data.id;
							this.editedItem = Object.assign({}, r.data.data);
							this.dialog = true;
						} else {
							this.snackbar = true;
							this.text =
								"<p>Ocurrió un problema al recuperar el registro.<br><hr><b>Codigo:</b>" +
								r.data.code +
								" " +
								r.data.message +
								"</p>";
							this.color = "error";
						}
					} else {
						if (r.data && r.data.status == 200) {
							this.color = "success";
							this.snackbar = true;
							this.text = "Registro " + text1;
							this.close();
							this.initialize();
						} else {
							this.snackbar = true;
							this.text =
								"<p>Ocurrió un problema al " +
								text2 +
								" el registro.<br><hr><b>Codigo:</b>" +
								r.data.code +
								" " +
								r.data.message +
								"</p>";
							this.color = "error";
						}
					}
				} else {
					this.snackbar = true;
					this.text = "Error al " + text2 + " los datos. Error: " + r.message;
					this.color = "error";
				}
			},
		},
		mounted() {
			console.log("Componente " + this.title + " creado");
		},
	};
</script>
